<template>
  <div>
    <v-card class="pa-0 pb-10" tile>
      <v-card-title>
        <h4 class="primary--text">
          {{ $t("userProfile.title") }}
        </h4>
      </v-card-title>
      <v-tabs
        v-if="isCustomerServiceProfile"
        v-model="tab"
        :height="60"
        background-color="grey lighten-5"
      >
        <v-tab> {{ $t("userProfile.tabs.info") }} </v-tab>
        <v-tab> {{ $t('userProfile.tabs.permissions')}}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" v-if='isCustomerServiceProfile && user'>
        <v-tab-item>
          <Information
            :user='user'
            :isCustomerServiceProfile="isCustomerServiceProfile"
          />
        </v-tab-item>
        <v-tab-item>
          <Permissions :user='user'></Permissions>
        </v-tab-item>
      </v-tabs-items>
      <Information
        v-else
        :user="user"
        :isCustomerServiceProfile="isCustomerServiceProfile"
      />
    </v-card>
  </div>
</template>

<script>
import Service from '@/services'
export default {
  name: 'UserProfile',
  components: {
    Information: () => import('./tabs/Informations.vue'),
    Permissions: () => import('./tabs/Permissions.vue')
  },
  computed: {
    userId () {
      return this.$route.params.userId
    },
    isCustomerServiceProfile () {
      return this.$route.name === 'CustomerServiceUserProfile'
    }
  },
  data () {
    return {
      tab: null,
      user: null
    }
  },
  methods: {
    async getUser () {
      if (this.isCustomerServiceProfile) {
        const response = await Service.get('UserManagement/GetUser/' + this.userId)
        this.user = response.data
      } else {
        const { data } = await Service.get(`User/GetCompanyUserProfile?userId=${this.userId}`)
        this.user = data
      }
    }
  },
  async mounted () {
    await this.getUser()
  }
}
</script>
